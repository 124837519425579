import type {ReactNode} from 'react'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import {spacing} from '../flexible-web-toolkit/styles'
import {useTrustcode} from './fetcher'
import {useDeviceId} from './device-id'

const Frame = ({
	children,
	src,
	alt,
	text,
}: {
	children: ReactNode
	src?: string
	alt?: string | null
	text: string
}): JSX.Element => (
	<div className="flex flex-col">
		{/* Header */}
		<div className="container-wide my-2 flex items-center gap-2 self-center lg:mx-auto">
			<div>
				{src ? (
					// eslint-disable-next-line @next/next/no-img-element -- Necessary for styling
					<img alt={alt ?? undefined} className="max-h-6" src={src} />
				) : null}
			</div>
			<h2 className="text-lg">{text}</h2>
		</div>
		{/* Remaining content */}
		{children}
	</div>
)

export type TrustcodeWidgetContentItem = IContentItem<{
	validBlocks: Elements.LinkedItemsElement
	deactivatedUnknownBlocks: Elements.LinkedItemsElement
	scannedMoreThanThresholdBlocks: Elements.LinkedItemsElement
	counterfeitBlocks: Elements.LinkedItemsElement
	maximumThreshold: Elements.NumberElement
	errorMessage: Elements.RichTextElement
	loadingMessage: Elements.RichTextElement
	brandLogo: Elements.AssetsElement
	brandText: Elements.TextElement
}>

export const TrustcodeWidgetBlock: Block<TrustcodeWidgetContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	const {deviceId} = useDeviceId()
	const {
		query: {code},
	} = useRouter()

	const brandLogo = block.elements.brandLogo[0]

	// Collect data from the Trustcode API
	const parsedCode = typeof code === 'string' ? code : (code ?? []).join(',')
	const {status, data} = useTrustcode(parsedCode, deviceId)

	// Short if no code is specified
	if (!code) {
		return (
			<Frame
				alt={brandLogo?.description}
				src={brandLogo?.url}
				text={block.elements.brandText}
			>
				<p className="text-center">No code found</p>
			</Frame>
		)
	}

	if (status === 'loading' || status === 'idle' || !data) {
		return (
			<Frame
				alt={brandLogo?.description}
				src={brandLogo?.url}
				text={block.elements.brandText}
			>
				<section
					className={`container-wide mx-auto w-fit text-center ${spacing.default}`}
				>
					<LoadingSpinner theme="green" />
					<h2
						dangerouslySetInnerHTML={{
							__html: block.elements.loadingMessage.value,
						}}
					/>
				</section>
			</Frame>
		)
	}

	// In the event of an error, display appropriate message
	if (status === 'error') {
		return (
			<Frame
				alt={brandLogo?.description}
				src={brandLogo?.url}
				text={block.elements.brandText}
			>
				<section
					className={`container-wide mx-auto w-fit ${spacing.default}`}
				>
					<h2
						dangerouslySetInnerHTML={{
							__html: block.elements.errorMessage,
						}}
					/>
				</section>
			</Frame>
		)
	}

	// If the product is defined as counterfeit, it should always display the prompt that it is counterfeit
	if (data.counterfeited) {
		return (
			<Frame
				alt={brandLogo?.description}
				src={brandLogo?.url}
				text={block.elements.brandText}
			>
				<section className="bg-[#FD8A8A] p-4">
					<BlockMapper
						blocks={block.elements.counterfeitBlocks}
						{...context}
					/>
				</section>
			</Frame>
		)
	}

	// If the product is valid, check if it is over threshold and display appropriate response.
	if (data.status === 'VALID') {
		if (
			data.aggregateData.differentDevicesCheckCount >
			(block.elements.maximumThreshold ?? 0)
		) {
			// Exceeded threshold
			return (
				<Frame
					alt={brandLogo?.description}
					src={brandLogo?.url}
					text={block.elements.brandText}
				>
					<section className="bg-[#FDF7C3] p-4">
						<BlockMapper
							blocks={
								block.elements.scannedMoreThanThresholdBlocks
							}
							{...context}
						/>
					</section>
				</Frame>
			)
		}

		// Under (or at) threshold
		return (
			<Frame
				alt={brandLogo?.description}
				src={brandLogo?.url}
				text={block.elements.brandText}
			>
				<section className="bg-[#CEEDC7] p-4">
					<BlockMapper
						blocks={block.elements.validBlocks}
						{...context}
					/>
				</section>
			</Frame>
		)
	}

	// If we don't recognize the code, return appropriate blocks
	return (
		<Frame
			alt={brandLogo?.description}
			src={brandLogo?.url}
			text={block.elements.brandText}
		>
			<section className="bg-[#FDF7C3] p-4">
				<BlockMapper
					blocks={block.elements.deactivatedUnknownBlocks}
					{...context}
				/>
			</section>
		</Frame>
	)
}
